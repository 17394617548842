<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo intervento</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaIntervento()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Sede cliente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Sede
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="sede_cliente"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.sede_cliente"
                                    >
                                        <ion-select-option
                                            v-for="sede in sedi_cliente"
                                            :key="sede.customers_shipping_address_id"
                                            :value="sede.customers_shipping_address_id"
                                        >
                                            {{ `${sede.customers_shipping_address_city}, ${sede.customers_shipping_address_street} ` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetti cliente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Progetti
                                </div>
                                <div class="value">
                                    <ion-select name="progetto" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.progetto">
                                        <ion-select-option v-for="progetto in progetti" :key="progetto.projects_id" :value="progetto.projects_id">
                                            {{ `${progetto.projects_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2024"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        value="1990-02-19T07:43Z"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Ore da fatturare -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Ore da fatturare <small>(lasciare vuoto per calcolo automatico)</small></div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.ore_fatturabili"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tipologia -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Tipo
                                </div>
                                <div class="value">
                                    <ion-select name="tipo" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tipo">
                                        <ion-select-option
                                            v-for="tipo in tipologia_intervento"
                                            :key="tipo.tickets_reports_type_id"
                                            :value="tipo.tickets_reports_type_id"
                                        >
                                            {{ `${tipo.tickets_reports_type_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Stato lavori -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Stato lavori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="stato_lavori"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.stato_lavori"
                                    >
                                        <ion-select-option
                                            v-for="stato in stati_lavoro"
                                            :key="stato.tickets_reports_stato_lavori_id"
                                            :value="stato.tickets_reports_stato_lavori_id"
                                        >
                                            {{ `${stato.tickets_reports_stato_lavori_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tecnici -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Tecnici
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tecnici">
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                                            {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Descrizione
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="2"
                                        v-model="intervento.descrizione"
                                        placeholder="Descrizione lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions_firme">
                            <button type="button" class="btn_firma" :class="{ btn_signed: customerHasSigned }" @click="openSignature('cliente')">
                                Firma cliente
                            </button>
                            <button type="button" class="btn_firma" :class="{ btn_signed: technicianHasSigned }" @click="openSignature('tecnico')">
                                Firma tecnico
                            </button>
                        </div>

                        <div class="action">
                            <button type="submit" class="btn_crea_intervento">
                                Salva
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiClienti from "@/services/clienti";
import apiProgetti from "@/services/progetti";
import apiTickets from "@/services/tickets";
import apiInterventi from "@/services/interventi";
import apiTecnici from "@/services/tecnici";

import ModalFirmaIntervento from "@/components/firme/ModalFirmaIntervento";

export default defineComponent({
    name: "InterventoProgramma",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props.data);

        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 2);

        const userID = JSON.parse(`${LOCALSTORAGE_KEY}`).dipendenti_user_id;

        const intervento = reactive({
            cliente: null,
            sede_cliente: "",
            progetto: null,
            ticket: null,
            data: start_date.toISOString(),
            ora_inizio: start_hour.toISOString(),
            ora_fine: end_hour.toISOString(),
            ore_fatturabili: "",
            tipo: "",
            stato_lavori: "",
            tecnici: [],
            descrizione: "",
            firma_tecnico: "",
            firma_cliente: "",
        });

        const successResponse = ref(false);
        const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

        /**
         * ! Open modal to sign
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);
        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? intervento.firma_tecnico : intervento.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            intervento.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da intervento
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            intervento.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }

        function closeCustomerSelection() {
            showCustomer.value = false;
        }

        /**
         * Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            } finally {
                //console.log(customers.value);
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "");

            return customers.value.filter((cliente) => {
                //company, nome/cognome, province
                if (cliente.customers_company) {
                    return (
                        cliente.customers_company
                            .toLowerCase()
                            .replace(/ /g, "")
                            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                    );
                } else {
                    if (cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_last_name + cliente.customers_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1 ||
                            (cliente.customers_name + cliente.customers_last_name)
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (cliente.customers_name && !cliente.customers_last_name) {
                        return (
                            cliente.customers_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    } else if (!cliente.customers_name && cliente.customers_last_name) {
                        return (
                            cliente.customers_last_name
                                .toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
                        );
                    }
                }
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
            }
        }

        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";

                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Get customer shipping address
         */
        const sedi_cliente = ref([]);
        async function loadSediCliente(customer_id) {
            if (customer_id) {
                try {
                    const res = await apiClienti.getSediCliente(customer_id);
                    sedi_cliente.value = res.data;
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante la richiesta delle sedi del ciente", "toast_danger");
                }
            }
        }

        /**
         * ! Get customer projects
         */
        const progetti = ref([]);
        async function loadProgetti(customer_id) {
            try {
                const res = await apiProgetti.getClienteCommesse(customer_id);
                if (res.status === 0) {
                    progetti.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei progetti", "toast_danger");
                }
            } catch (error) {
                progetti.value = [];
                openToast("Errore durante la richiesta dei progetti", "toast_danger");
            }
        }

        const tipologia_intervento = ref([]);
        async function loadTipologieIntervento() {
            try {
                const res = await apiInterventi.getTipologieIntervento();
                tipologia_intervento.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle tipologie di intervento effettuabili", "toast_danger");
            }
        }

        const stati_lavoro = ref([]);
        async function loadStatiLavoro() {
            try {
                const res = await apiInterventi.getStatoLavori();
                stati_lavoro.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli stati dei lavori", "toast_danger");
            }
        }

        const tecnici = ref([]);
        async function loadTecnici() {
            try {
                const res = await apiTecnici.getTecnici();
                tecnici.value = res;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei tecnici", "toast_danger");
            }
        }

        /**
         * ! Watch customer selection to get sede and project data
         */

        watch(
            () => selectedCustomer.value,
            (newCustomer, oldCustomer) => {
                console.log("newCustomer", newCustomer); // => ref(undefined)
                //Get customer shipping address
                loadSediCliente(newCustomer.customers_id);
                //Get customer projects
                loadProgetti(newCustomer.customers_id);
            }
        );

        /**
         * ! Watch customer selection to get sede and project data
         */

        watch(
            () => selectedCustomer.value,
            (newCustomer, oldCustomer) => {
                console.log("newCustomer", newCustomer); // => ref(undefined)
                //Get customer shipping address
                loadSediCliente(newCustomer.customers_id);
                //Get customer projects
                loadProgetti(newCustomer.customers_id);
            }
        );

        /**
         * ! Create new intervento
         */
        async function creaIntervento() {
            const tecnico_id = userID; //set technician as logged user id
            //Controllo che ci sia almeno un tecnico selezioanto
            if (intervento.tecnici.length === 0) {
                openToast("Non puoi creare un intervento senza selezionare i tecnici", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra i tecnici selezionati
            const isUSerSelected = intervento.tecnici.includes(userID);
            if (!isUSerSelected && intervento.tecnici.length != 0) {
                openToast("Non puoi creare un intervento senza essere tra i tecnici selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(intervento.ora_inizio);
            const ora_fine = new Date(intervento.ora_fine);
            if (ora_inizio > ora_fine) {
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }
            //Controllo che stato lavori sia selezioanto
            if (!intervento.stato_lavori) {
                openToast("Lo stato dei lavori è obbligatorio", "toast_danger");
                return;
            }

            if (!selectedCustomer.value) {
                openToast("Il cliente è obbligatorio", "toast_danger");
                return;
            }

            const data_inizio_formatted = moment(intervento.data_inizio).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(intervento.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(intervento.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("tickets_reports_technician", tecnico_id); //logged user
            data.append("tickets_reports_customer_id", selectedCustomer.value.customers_id);
            data.append("tickets_reports_project_id", intervento.progetto ? intervento.progetto : null);
            data.append("tickets_reports_customer_address", intervento.sede_cliente ? intervento.sede_cliente : null);
            /*       data.append("tickets_reports_project_id", props.data.value.tickets_project_id ? props.data.value.tickets_project_id : null);
      data.append("tickets_reports_ticket_id", props.data.value.tickets_id ? props.data.value.tickets_id : null); */
            data.append("tickets_reports_date", data_inizio_formatted);
            data.append("tickets_reports_start_time", ora_inizio_formatted);
            data.append("tickets_reports_end_time", ora_fine_formatted);
            data.append("tickets_reports_billable_hours", intervento.ore_fatturabili);
            data.append("tickets_reports_type", intervento.tipo);
            data.append("tickets_reports_stato_lavori", intervento.stato_lavori);
            intervento.tecnici.forEach((tecnico) => {
                data.append("tickets_reports_tecnici[]", tecnico);
            });
            data.append("tickets_reports_description", intervento.descrizione);

            if (intervento.firma_tecnico) {
                data.append("tickets_reports_firma_operatore_b64", intervento.firma_tecnico);
            }
            if (intervento.firma_cliente) {
                data.append("tickets_reports_firma_cliente_b64", intervento.firma_cliente);
            }

            try {
                const response = await apiInterventi.saveIntervento(data);
                if (response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la creazione dell'intervento", "toast_danger");
            }
        }

        onMounted(() => {
            loadCustomer();
            //loadSediCliente();
            loadTipologieIntervento();
            loadStatiLavoro();
            loadTecnici();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaIntervento,
            intervento,
            sedi_cliente,
            progetti,
            tipologia_intervento,
            stati_lavoro,
            tecnici,
            // Clienti
            searchQuery,
            searchedCustomers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            selectedCustomer,
            setActiveCustomer,
            setSelectedCustomer,
            //Firme
            openSignature,
            technicianHasSigned,
            customerHasSigned,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
ion-button {
    --color: #ffffff;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    margin-bottom: 16px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}
</style>
